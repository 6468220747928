import React, { useContext, useState } from "react";
import Modal from "react-modal";
import { UserContext } from "../../Context/UserContext";
import { auth } from "../../firebase";
import { storeFeedBack } from "../../firebase/StoreFeedback";
import Close from "../icons/close";
import Loader from "../icons/Loader";
import { serverTimestamp } from "firebase/firestore";

export default function Feedback({ FeedbackcloseModal, feedbackOpen }) {
  const { displayName, phoneNumber } = auth.currentUser ? auth.currentUser : {};
  const [name, setname] = useState(displayName ? displayName : "");
  const [loader, setLoader] = useState(false);
  const [emailOrPhone, setemailOrPhone] = useState(
    phoneNumber ? phoneNumber : ""
  );
  const { showToast } = useContext(UserContext);
  const [feedback, setfeedback] = useState("");

  const handleNameInputChange = (e) => {
    setname(e.target.value);
  };
  const handlePhoneOrEmailInputChange = (e) => {
    setemailOrPhone(e.target.value);
  };

  const handleFeedBackInput = (e) => {
    setfeedback(e.target.value);
  };

  const onSubmitFeedBack = (e) => {
    e.preventDefault();
    setLoader(true);
    if (feedback.trim() && name.trim()) {
      storeFeedBack({
        feedBakMessage: feedback,
        name: name,
        createdAt: serverTimestamp(),
        emailOrPhone: emailOrPhone,
      })
        .then(() => {
          FeedbackcloseModal();
          showToast("Feedback submitted successfully!", "success");
        })
        .catch(() => {
          showToast(
            "Unable to submit feedback. We will resolve it asap!",
            "error"
          );
        })
        .finally(() => {
          setLoader(false);
        });
    } else {
      showToast("Please fill mandatory fields!", "error");
      setLoader(false);
    }
  };

  return (
    <Modal
      isOpen={feedbackOpen}
      onRequestClose={FeedbackcloseModal}
      className="modal-main feedbackmain"
      shouldCloseOnOverlayClick={false}
    >
      <a href="#" className="closenow" onClick={FeedbackcloseModal}>
        <Close />
      </a>
      <div className="feedback">
        <h4>Provide your feedback</h4>
        <form className="feedbacknow">
          <div className="nameinputfield">
            <input
              value={name}
              required
              type="text"
              name="name"
              placeholder="Your name"
              onChange={handleNameInputChange}
            />
            <input
              onChange={handlePhoneOrEmailInputChange}
              value={emailOrPhone}
              type="text"
              name="phone"
              placeholder="Your Email or Phone"
            />
          </div>
          <textarea
            required
            onChange={handleFeedBackInput}
            name="text"
            placeholder="What do you like about the Shivam.Chat"
            rows="4"
            cols="50"
          />
          <button disabled={loader} className="btn" onClick={onSubmitFeedBack}>
            {loader ? <Loader /> : "Submit"}
          </button>
        </form>
      </div>
    </Modal>
  );
}
