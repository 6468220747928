import { createContext, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const UserContext = createContext()

export const UserContextProvider = ({children}) => {
    const [showNameModal, setShowNameModal] = useState(false)
    const showToast = (message, type) => {
      toast[type](message, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    };
    
    return (
      <UserContext.Provider value={{showNameModal, setShowNameModal,showToast}}>
        {children}
      </UserContext.Provider>
    )
}