import React, { useContext, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../../pages/Home";
import PrivateChat from "../../pages/Private-Chat";
import { useLottie } from "lottie-react";
import animationData from "../../assets/images/loding.json";
import { onAuthStateChanged, RecaptchaVerifier } from "firebase/auth";
import { auth } from "../../firebase";
import { UserContext } from "../../Context/UserContext";

export default function Navigation() {
  const [loading, setLoading] = useState(true);
  const [loggedIn, setloggedIn] = useState(false);
  const { setShowNameModal } = useContext(UserContext);
  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setLoading(false);
    }, 1500);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setloggedIn(true);
        if (!user.displayName) {
          setShowNameModal(true);
        }
      } else {
        setloggedIn(false);
      }
    });
    return () => {
      clearTimeout(loadingTimeout);
    };
  }, []);

  useEffect(() => {
    auth.useDeviceLanguage();
    window.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      "phone-sign-in-button",
      {
        size: "invisible",
        callback: (response) => {
          // response after captcha verify
        },
      }
    );
  }, []);

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const { View } = useLottie(lottieOptions);

  if (loading) {
    return <div className="lodingmain">{View}</div>;
  }
  if (!loggedIn) {
    return (
      <Routes>
        <Route exact path="*" element={<Home />}></Route>
      </Routes>
    );
  }
  return (
    <Routes>
      <Route exact path="/" element={<Home />}></Route>
      <Route exact path="/private-chat" element={<PrivateChat />}></Route>
    </Routes>
  );
}
