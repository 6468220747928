import {
  onValue, push,
  ref,
  serverTimestamp,
  set
} from "firebase/database";
import { useContext, useEffect, useState } from "react";
import { auth, realTimeDatabase } from ".";
import { UserContext } from "../Context/UserContext";
import { pingServerOnNewMessage } from "./API";

export const useMessagesHandler = () => {
  const { showToast } = useContext(UserContext);
  const [promptId, setPromptId] = useState("");
  useEffect(()=>{
    const promptMessageRef = ref(realTimeDatabase, "prompt");
    const promptId = onValue(promptMessageRef, (snapshot) => {
      if (snapshot.exists()) {
        const promptData = snapshot.val();
        const newPromptId = promptData.promptId;
        setPromptId(newPromptId);
        // Do something with promptId if needed
      }
    });
    return ()=>{
      promptId();
    }
  },[])
  const sendMessage = async (message, setMessage) => {
    try {
      if (!auth.currentUser?.uid) {
        // Handle the case where the user is not authenticated
        return;
      }
      const uid = auth.currentUser.uid;
      const userMessagesRef = ref(realTimeDatabase, `Chats/${uid}/messages`);
      const newMessageRef = push(userMessagesRef);
      await set(newMessageRef, {
        text: message,
        createdAt: serverTimestamp(),
        uid,
        promptId: promptId,
      });
      auth.currentUser.getIdToken().then((token) => {
        pingServerOnNewMessage(token).catch(() => {
          showToast("Server Down!", "error");
        });
      });
      setMessage("");
    } catch (error) {
      showToast("Unable to send messages. Please try again!", "error");
    }
  };

  // now i want get all message then then update filed status to inactive
  const resetChats = async () => {
    const uid = auth.currentUser.uid;
    const lastDeletedRef = ref(realTimeDatabase, `Chats/${uid}/last_deleted`);
    await set(lastDeletedRef, serverTimestamp());
  };

  return { sendMessage, resetChats };
};
