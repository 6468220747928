export const pingServerOnNewMessage = async (firebaseToken) => {
  const API_URL_PING_SERVER = process.env.REACT_APP_API_BASE_URL
  fetch(API_URL_PING_SERVER, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      firebase_token: firebaseToken,
    }),
  })
}

export const mergeMessages = async (firebaseToken, previous_user_id) => {
  const API_URL_PING_SERVER = process.env.REACT_APP_API_BASE_URL+'merge_messages'
  fetch(API_URL_PING_SERVER, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      firebase_token: firebaseToken,
      previous_user_id: previous_user_id
    }),
  })
}

