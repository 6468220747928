import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../component/header";
import Send from "../../component/icons/send";
import { auth } from "../../firebase";
import { useAuthentication } from "../../firebase/Authentication";
import { useMessagesHandler } from "../../firebase/useMessagesHandler";

export default function Home({ navigation }) {
  const [message, setMessage] = useState("");
  const { sendMessage } = useMessagesHandler();
  const navigate = useNavigate();
  const { anonymousSignIn } = useAuthentication();

  const defaultMessages = [
    "What career advice do you have for someone in college",
    "How can I get a referral from you",
    "What do you work on",
  ];

  const onSendMessage = (e, messageTobeSent) => {
    e.preventDefault();
    if (!auth?.currentUser?.uid) {
      anonymousSignIn().then(() => {
        readyToSend(messageTobeSent);
      });
    } else {
      readyToSend(messageTobeSent);
    }
  };

  const readyToSend = (messageTobeSent) => {
    if (messageTobeSent.trim() === "") {
      alert("Enter a valid message");
      return;
    }
    sendMessage(messageTobeSent, setMessage);
    navigate("/private-chat");
  };

  const sendDefaultMessage = (e, text) => {
    onSendMessage(e, text);
  };

  return (
    <div className="container-fluid">
      <Header />
      <div className="chatspaceing">
        <div className="fixed-width">
          <div className="chat-details">
            {auth?.currentUser?.uid && !auth?.currentUser?.displayName ? (
              <h3>
                Hi, User
                {auth?.currentUser?.uid.substr(
                  auth?.currentUser?.uid.length - 4
                )}{" "}
                👋
              </h3>
            ) : (
              auth?.currentUser?.displayName && (
                <h3>
                  Hi, {auth?.currentUser?.displayName}
                  👋
                </h3>
              )
            )}
            <h2>How may I help you today?</h2>
            <form className="search-massage">
              <input
                className="inputfield"
                name="name"
                type="text"
                value={message}
                onChange={(e) => setMessage(e.currentTarget.value)}
                placeholder="Send a message"
              />
              <button
                onClick={(e) => onSendMessage(e, message)}
                className="btn"
              >
                <Send />
              </button>
            </form>
            <div className="career-advice">
              {defaultMessages.map((defaultmsg, index) => {
                return (
                  <p
                    key={index}
                    onClick={(e) => sendDefaultMessage(e, defaultmsg)}
                  >
                    {defaultmsg}
                  </p>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
