import React from "react";
import Modal from "react-modal";
import { useMessagesHandler } from "../../firebase/useMessagesHandler";

export default function ResetModal({ setResetOpen, resetOpen, setMenuOpen }) {
  const { resetChats } = useMessagesHandler();
  const handleResetChats = async () => {
    resetChats().then(() => {
      setResetOpen(false);
      setMenuOpen(true);
    });
  };

  const modalClose = () => {
    setResetOpen(false);
    setMenuOpen(true);
  };
  return (
    <Modal
      isOpen={resetOpen}
      onRequestClose={() => setResetOpen(false)}
      className="modal-main resetmodal"
      shouldCloseOnOverlayClick={false}
    >
      <div className="phonenumber">
        <h1>Are you sure you want to reset the chat?</h1>
        <div className="otpnumber">
          <button className="btn nobtn" onClick={modalClose}>
            No
          </button>
          <button className="btn" onClick={handleResetChats}>
            Yes
          </button>
        </div>
      </div>
    </Modal>
  );
}
