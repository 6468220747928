import React, { useEffect, useState } from "react";
import LoginModal from "../LoginModal";
import Feedback from "../feedback";
import ResetModal from "../ResetModal";
import MenuIcon from "../icons/menuicon";
import CloseMenu from "../icons/CloseMenu";
import Logout from "../icons/logout";
import { auth } from "../../firebase";
import { useAuthentication } from "../../firebase/Authentication";

export default function Header() {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [resetOpen, setResetOpen] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(true);

  const { logout } = useAuthentication();

  useEffect(() => {
    const handleResize = () => {
      const isSmallScreen = window.matchMedia("(max-width:768px)").matches;
      setMenuOpen(!isSmallScreen);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const otpModal = () => {
    setIsOpen(true);
    setMenuOpen(false);
  };
  const closeModal = () => {
    setIsOpen(false);
    setMenuOpen(true);
  };

  const FeedbackotpModal = () => {
    setFeedbackOpen(true);
    setMenuOpen(false);
  };
  const FeedbackcloseModal = () => {
    setFeedbackOpen(false);
    setMenuOpen(true);
  };
  const ResetOpenModal = () => {
    setResetOpen(true);
    setMenuOpen(false);
  };

  return (
    <header className={`${isMenuOpen ? "blur" : ""}`}>
      <div className="logo">
        <h1>Shivam.Chat</h1>
      </div>
      <div className="navbar">
        <span onClick={toggleMenu}>
          {isMenuOpen ? <CloseMenu /> : <MenuIcon />}
        </span>
        {isMenuOpen && (
          <ul>
            <li>
              {/**
               * TODO Need to Discuss about the reset chat behaviour
               */}
              <a onClick={ResetOpenModal}>Reset Chat</a>
            </li>
            <li>
              <a href="#" onClick={FeedbackotpModal}>
                Feedback
              </a>
            </li>
            <li>
              {auth?.currentUser?.phoneNumber && (
                <button onClick={logout}>Logout</button>
              )}
            </li>
          </ul>
        )}
        {!auth?.currentUser?.phoneNumber && (
          <button id="login-button" className="btn" onClick={otpModal}>
            Login
          </button>
        )}
        {auth?.currentUser?.phoneNumber && (
          <a onClick={logout} className="logoutbtn">
            <Logout />
          </a>
        )}
      </div>
      <LoginModal closemodal={closeModal} modalIsOpen={modalIsOpen} />
      <Feedback
        FeedbackcloseModal={FeedbackcloseModal}
        feedbackOpen={feedbackOpen}
      />
      <ResetModal
        setResetOpen={setResetOpen}
        resetOpen={resetOpen}
        setMenuOpen={setMenuOpen}
      />
    </header>
  );
}
