import {
  linkWithCredential,
  PhoneAuthProvider,
  signInAnonymously,
  signInWithCredential,
  signOut,
} from "firebase/auth";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { useContext, useState } from "react";
import { auth, db } from ".";
import { UserContext } from "../Context/UserContext";
import { getLastFourCharacters } from "../util/Util";
import { mergeMessages } from "./API";
export const useAuthentication = () => {
  const { setShowNameModal, showToast } = useContext(UserContext);
  const [verificationId, setVerificationId] = useState("");
  /**
   * Anonymous Sign-in to send the messages and handle the unique id of the user session
   * only one session will be created.
   */
  const anonymousSignIn = async () => {
    try {
      await signInAnonymously(auth);
      await addUserToDatabase();
    } catch (error) {
      showToast("Signin failed. Please try to reload!", "error");
    }
  };

  /**
   * Login using phone number and send otp
   * @param {*} phoneNumber
   */
  const firebasePhoneAuth = async (phoneNumber) => {
    const appVerifier = window.recaptchaVerifier;
    const provider = new PhoneAuthProvider(auth);
    const phoneVerificationId = await provider.verifyPhoneNumber(
      phoneNumber,
      appVerifier
    );
    setVerificationId(phoneVerificationId);
  };

  /**
   * Verify OTP and Link the user to their annonymous account
   * @param {*} code
   */
  const verifyOTP = async (code) => {
      const phoneCredential = PhoneAuthProvider.credential(verificationId, code)
      if(auth.currentUser){
        const previous_user_id=auth.currentUser.uid
        await linkWithCredential(auth.currentUser, phoneCredential).catch(async(error)=>{
          if (error?.message.includes('auth/account-exists-with-different-credential')) {
            await logout()
            await signInWithCredential(auth, phoneCredential)
            auth.currentUser.getIdToken().then((token)=>{
              mergeMessages(token, previous_user_id)
            })
          }
        })
      } else {
        await signInWithCredential(auth, phoneCredential)
      }
      addUserToDatabase()
      if(!auth?.currentUser?.displayName){
        setShowNameModal(true)
      }
  }

  const addUserToDatabase = async () => {
    const userRef = doc(db, "Users", auth.currentUser.uid);
    const userDocSnap = await getDoc(userRef);
    const userData = {
      displayName: auth.currentUser.displayName
        ? auth.currentUser.displayName
        : `User_${getLastFourCharacters(auth.currentUser.uid)}`,
      phoneNumber: auth.currentUser.phoneNumber,
      uid: auth.currentUser.uid,
    };
    if (!userDocSnap.exists()) {
      await setDoc(userRef, userData);
    } else {
      await updateDoc(userRef, userData);
    }
  };

  /**
   * Logout the current session of the user
   */
  const logout = async() => {
    await signOut(auth)
  }
  return {
    logout,
    verifyOTP,
    firebasePhoneAuth,
    anonymousSignIn,
  };
};
