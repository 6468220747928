import { onValue, ref } from "firebase/database";
import { useLottie } from "lottie-react";
import React, { useEffect, useRef, useState } from "react";
import animationData from "../../assets/images/chatloding.json";
import Header from "../../component/header";
import Send from "../../component/icons/send";
import Star from "../../component/icons/star";
import { auth, realTimeDatabase } from "../../firebase";
import { useMessagesHandler } from "../../firebase/useMessagesHandler";

export default function PrivateChat() {
  const [message, setMessage] = useState("");
  const [showMessages, setShowMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [lastResetAt, setLastResetAt] = useState("");
  const { sendMessage } = useMessagesHandler();
  useEffect(() => {
    const uid = auth.currentUser?.uid;
    if (!uid) {
      // Handle the case where the user is not authenticated
      return;
    }
    const adminTypingRef = ref(realTimeDatabase, `Chats/${uid}/admin_typing`);
    const last_deletedRef = ref(realTimeDatabase, `Chats/${uid}/last_deleted`);
    const unsubscribeLast_deletedRef = onValue(last_deletedRef, (snapshot) => {
      if (snapshot.exists()) {
        const lastDeletedValue = snapshot.val();
        setLastResetAt(lastDeletedValue);
      }
    });
    const unsubscribeAdminTypingRef = onValue(adminTypingRef, (snapshot) => {
      if (snapshot.exists()) {
        const adminTyping = snapshot.val();
        setIsTyping(adminTyping);
      } else {
        setIsTyping(false);
      }
    });
    // getLastMessageValue().then((lastDeletedAt) => {});
    const userMessagesRef = ref(realTimeDatabase, `Chats/${uid}/messages`);
    const unsubscribe = onValue(userMessagesRef, (snapshot) => {
      if (snapshot.exists()) {
        const messagesData = snapshot.val();
        setShowMessages(messagesData);
      } else {
        setShowMessages([]);
      }
    });

    return () => {
      unsubscribeAdminTypingRef();
      unsubscribe();
      unsubscribeLast_deletedRef();
    };
  }, []);

  const onSendMessage = (e) => {
    e.preventDefault();
    if (message.trim() === "") {
      alert("Enter a valid message");
      return;
    }
    sendMessage(message, setMessage);
  };

  const chatScrollRef = useRef(null);
  useEffect(() => {
    chatScrollRef.current.scrollTop = chatScrollRef.current.scrollHeight;
  }, [showMessages]);

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const { View } = useLottie(lottieOptions);
  return (
    <div className="container-fluid">
      <div className="privateChat">
        <Header />
        <div className="chatspaceing">
          <div className="chatscrolling" ref={chatScrollRef}>
            <div className="fixed-width">
              {Object.keys(showMessages).map((messageKey) => {
                const msg = showMessages[messageKey];
                if (!lastResetAt || lastResetAt < msg.createdAt) {
                  let messageText = msg?.text?.replace(/'/g, "");
                  messageText = messageText?.replace(
                    /(?:\r\n|\r|\n)(?!\w)/g,
                    "<br/>"
                  );
                  return (
                    <div
                      key={messageKey}
                      className={
                        msg.uid === auth.currentUser.uid
                          ? "chatshow"
                          : "userside"
                      }
                    >
                      <div
                        className={
                          msg.uid === auth.currentUser.uid
                            ? "claintchat"
                            : "mychat"
                        }
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: messageText.replace(/\n/g, "<br/>"),
                          }}
                        />
                      </div>
                      {msg.uid === auth.currentUser.uid ? (
                        <span>
                          {auth.currentUser.displayName
                            ? auth.currentUser.displayName?.[0]
                            : auth.currentUser.uid?.[0]}
                        </span>
                      ) : (
                        <Star />
                      )}
                    </div>
                  );
                }
              })}
            </div>
          </div>

          <form className="search-massage">
            <div
              className="chatloding"
              style={{
                display: isTyping ? "block" : "none",
              }}
            >
              {View}
            </div>
            <input
              className="inputfield"
              name="name"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              type="text"
              placeholder="Send a message"
            />
            <button onClick={onSendMessage} className="btn">
              <Send />
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
