import { default as React, useContext, useState } from "react";
import Modal from "react-modal";
import OtpInput from "react-otp-input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-toastify/dist/ReactToastify.css";
import { UserContext } from "../../Context/UserContext";
import { useAuthentication } from "../../firebase/Authentication";
import Close from "../icons/close";
import Loader from "../icons/Loader";

export default function LoginModal({ closemodal, modalIsOpen }) {
  const [step, setStep] = useState("phone");
  const [otp, setOtp] = useState("");
  const [phone, setPhone] = useState("");
  const { showToast } = useContext(UserContext);
  const [loader, setLoader] = useState(false);

  const { firebasePhoneAuth, verifyOTP, logout } = useAuthentication();
  const handleStepChange = (newStep) => {
    setStep(newStep);
  };

  const handlePhoneInputChange = (value) => {
    const phonevalue = `+${value}`;
    setPhone(phonevalue);
  };

  const onSubmitPhone = async (e) => {
    e.preventDefault();
    setLoader(true);
    firebasePhoneAuth(phone)
      .then(() => {
        showToast("Sent verification code!", "success");
        handleStepChange("otp");
      })
      .catch(() => {
        showToast(" Invalid Phone.", "error");
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const onSubmitOTP = (e) => {
    e.preventDefault();
    setLoader(true);
    verifyOTP(otp)
      .then(() => {
        closemodal();
        showToast("OTP verified successfully", "success");
      })
      .catch((error) => {
        console.log(error.message);
        if (
          error?.message.includes(
            "auth/account-exists-with-different-credential"
          )
        ) {
          logout();
          showToast("Account already exists. Please Login again!", "error");
          setTimeout(() => {
            document.getElementById("login-button").click();
          }, 1000);
        } else {
          showToast("Invalid/Expired OTP", "error");
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closemodal}
      className="modal-main"
      shouldCloseOnOverlayClick={false}
    >
      <button className="closenow" onClick={closemodal}>
        <Close />
      </button>
      {step === "phone" && (
        <div className="phonenumber conversation">
          <h1>Login with your phone number to save the conversation history</h1>
          <form className="otpnumber">
            <PhoneInput
              onChange={(value) => handlePhoneInputChange(value)}
              value={phone}
              country={"us"}
              inputProps={{
                name: "phone",
                required: true,
                autoFocus: true,
              }}
            />
            <button disabled={loader} className="btn" onClick={onSubmitPhone}>
              {loader ? <Loader /> : "Send OTP"}
            </button>
          </form>
        </div>
      )}
      {step === "otp" && (
        <div className="otp-number">
          <h1>
            Enter the One Time Password (OTP) received on your mobile number
          </h1>
          <form>
            <div className="otpnumber">
              <OtpInput
                value={otp}
                inputType="tel"
                onChange={setOtp}
                numInputs={6}
                renderInput={(props) => <input {...props} placeholder="-" />}
              />
            </div>
            <div className="submitnow">
              <button disabled={loader} className="btn" onClick={onSubmitOTP}>
                {loader ? <Loader /> : "Verify"}
              </button>
            </div>
          </form>
        </div>
      )}
    </Modal>
  );
}
