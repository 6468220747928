import Navigation from "./component/Navigation";
import { BrowserRouter } from "react-router-dom";
import "../src/assets/css/style.scss";
import AskNameModal from "./component/AskName/AskNameModal";
import { UserContextProvider } from "./Context/UserContext";
import { Fragment } from "react";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <BrowserRouter>
        <UserContextProvider>
          <Fragment>
            <Navigation />
            <AskNameModal />
          </Fragment>
        </UserContextProvider>
        <div id="phone-sign-in-button"></div>
      </BrowserRouter>
    </div>
  );
}

export default App;
