import { updateCurrentUser, updateProfile } from "firebase/auth";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import React, { useContext, useState } from "react";
import Modal from "react-modal";
import { UserContext } from "../../Context/UserContext";
import { auth, db } from "../../firebase";

export default function AskNameModal() {
  const { showNameModal, setShowNameModal } = useContext(UserContext);
  const [name, setName] = useState("");
  const closemodal = () => {
    setShowNameModal(false);
  };

  const updateTheUserData = (e) => {
    e.preventDefault();
    if (name.trim() === "") {
      alert("Please enter valid name");
      return;
    }
    if (auth?.currentUser) {
      updateProfile(auth.currentUser, { displayName: name }).then(() => {
        const userProfileRef = doc(db, "Users", auth?.currentUser?.uid);
        updateDoc(userProfileRef, {
          displayName: name,
        });
        closemodal();
      });
    }
  };

  return (
    <Modal
      isOpen={showNameModal}
      onRequestClose={closemodal}
      className="modal-main"
    >
      <div className="otp-umber">
        <h1>Enter your name to begin with Shivam.Chat</h1>
        <form className="otpnumber">
          <input
            className="inputfieldname"
            onChange={(e) => {
              setName(e.currentTarget.value);
            }}
            type="text"
            name="name"
            placeholder="Name"
          />
          <button className="btn" onClick={updateTheUserData}>
            Continue
          </button>
        </form>
      </div>
    </Modal>
  );
}
