// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getDatabase } from 'firebase/database'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAgdsl-H8_8eAkKUG7eFikLV6OnVEqZCss',
  authDomain: 'shivam-ai.firebaseapp.com',
  databaseURL: 'https://shivam-ai-default-rtdb.firebaseio.com',
  projectId: 'shivam-ai',
  storageBucket: 'shivam-ai.appspot.com',
  messagingSenderId: '69384233188',
  appId: '1:69384233188:web:476e23619a806d38174cf1',
  measurementId: 'G-95LT1LQGHC',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
/**
 * Will use analytics later
 */
const analytics = getAnalytics(app)
// Initialize Realtime Database and get a reference to the service
export const auth = getAuth(app)
export const realTimeDatabase = getDatabase(app)
export const db = getFirestore(app);
